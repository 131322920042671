import DatePicker from 'vue2-datepicker';
export default {
	name: 'selectInvoice',
	components: {
		DatePicker
	},
	data() {
		return {
			recoveryList: [],
			recoveryFields: [
				{
					key: 'selectbox',
					label: 'Select All'
				},
				{
					key: 'lease_number'
				},
				{
					key: 'customer_name'
				},
				{
					key: 'customer_site'
				},
				{
					key: 'invoice_type'
				},
				{
					key: 'invoice_no'
				},
				{
					key: 'invoice_date'
				},
				{
					key:'UOM'
				},
				{
					key: 'number'
				},
				{
					key: 'amount'
				},
				{
					key: 'invoice_amount'
				},
				{
					key: 'status'
				},
				{
					key: 'sd_account'
				},
				{
					key: 'status'
				},
				{
					key: 'sd_balance'
				},
				{
					key: 'receipt_payment'
				},
				{
					key: 'add'
				},
				{
					key: 'remove'
				}
			]
		}
	},
	mounted() { },
	methods: {
	}
}